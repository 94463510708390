import React from "react";
import { graphql, Link } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image";
import { ContactParticle, SubLayout } from "../components";

import Seo from "../components/Seo";

const BlogPostTemplate = ({ data: { post } }) => {
  const category = post.categories.nodes[0].name;

  return (
    <SubLayout>
      <Seo title={post.title} description={post.excerpt} />
      <div className="mt-32 lg:mt-48 px-4 max-w-5xl mx-auto tracking-wider md:px-8 mb-16 md:mb-24">
        <div className="flex flex-col mb-8 lg:mb-16">
          <span className="banner-font text-6xl text-tertiary-blue font-medium">
            News
          </span>
          <span className="text-primary-blue font-medium -mt-8">お知らせ</span>
        </div>
        <div className="max-w-4xl mx-auto">
          <div className="mb-4 lg:mb-8">
            <h1 className="text-xl font-bold mb-2 lg:text-2xl">{post.title}</h1>
            <div className="flex flex-col gap-y-0.5">
              <div className="w-10 h-0.5 bg-primary-yellow" />
              <div className="w-10 h-0.5 bg-primary-yellow" />
              <div className="w-10 h-0.5 bg-primary-yellow" />
            </div>
          </div>
          <div className="mb-12">
            <div className="flex gap-x-1.5 items-center text-white text-xs lg:text-sm font-medium bg-primary-blue px-2 py-1.5 w-fit-content">
              <span>{post.date}</span>
              <span>|</span>
              <span>{category}</span>
            </div>
          </div>
          <div className="mb-16 lg:mb-24">
            <div
              className="content text-sm tracking-wider leading-loose lg:text-base"
              dangerouslySetInnerHTML={{
                __html: `${post.content}`,
              }}
            />
          </div>
          <div className="w-full h-0.5 bg-tertiary-blue" />
        </div>
        <Link
          to="/news"
          className="mt-8 lg:mt-12 text-white text-sm font-medium text-center py-4 bg-primary-blue border-2 border-primary-blue block w-60 mx-auto rounded-lg hover:bg-white hover:text-primary-blue transform duration-300"
        >
          お知らせ一覧へ戻る
        </Link>
      </div>
      <ContactParticle />
    </SubLayout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    post: wpPost(id: { eq: $id }) {
      id
      categories {
        nodes {
          name
        }
      }
      date(formatString: "Y.M.DD")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      content
      title
    }
  }
`;
